import "monday-ui-react-core/tokens";

import Calendar from "./componants/MyCalendar";
import { ConfigProvider, Spin, Tabs } from "antd";
import { useCallback, useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import * as jose from "jose";
import Timesheet from "./componants/Timesheet";
import * as Sentry from "@sentry/react";
import styles from "./App.module.css";
import { Button, Modal, ModalContent } from "monday-ui-react-core";
import { BACKEND_URL } from "./util/constants";

Sentry.init({
  dsn: "https://d1c534569687b895f889930cf78ff136@o4506785929297920.ingest.us.sentry.io/4506933202780160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://my.worklogged.123x.dev/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const monday = mondaySdk();
function App() {
  const [theme, setTheme] = useState("light"); // 'dark', 'hacker_theme', 'black'
  const [sessionToken, setSessionToken] = useState<string>("");
  const [userId, setUserId] = useState<string | null>(null);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [isOpenConsentScreen, setIsOpenConsentScreen] = useState<boolean>(true);
  const [isOauthScreen, setIsOauthScreen] = useState<boolean>(true);
  const [interactiveDemo, setInteractiveDemo] = useState<boolean>(false); // Change this to show only for first time
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    monday
      .execute("valueCreatedForUser")
      .catch((err) => console.error("error in monday", err));
    monday.listen("context", (res: { data: { theme: string } }) => {
      setTheme(res.data.theme);
    });
  }, []);

  useEffect(() => {
    const themeName = `${theme}-app-theme`;
    document.body.className = themeName;
    return () => {
      document.body.className = "";
    };
  }, [theme]);

  useEffect(() => {
    monday
      .get("sessionToken")
      .then((token) => {
        setSessionToken(token.data);
        if (!token || !token.data) {
          // possible local
          console.log("is this LOCAL?");
          return;
        }
        const data: { dat: { user_id: string; is_view_only: boolean } } =
          jose.decodeJwt(token.data);
        setUserId(data.dat.user_id);
        setIsViewOnly(data.dat.is_view_only);
      })
      .catch((e) => console.error(e));
  }, [theme]);
  useEffect(() => {
    if (!sessionToken) return;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", sessionToken);
    const requestOptions: RequestInit = {
      method: "GET",
      headers: headers,
    };
    fetch(`${BACKEND_URL}/cookieConsent`, requestOptions).then(
      async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const res = await response.json();

        if (res && res.isConsented) {
          setIsOpenConsentScreen(false);
        }
        if (res) {
          setInteractiveDemo(!res.isMyTimeDemo);
        }
        if (res && res.isOauthDone) {
          setIsOauthScreen(false);
        }
        setIsLoading(false);
      }
    );
  }, [sessionToken, setIsOpenConsentScreen]);

  const agreeConsent = useCallback(() => {
    if (!sessionToken) return;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", sessionToken);
    const requestOptions: RequestInit = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ test: 1 }),
    };
    fetch(`${BACKEND_URL}/cookieConsent`, requestOptions).then(
      async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const res = await response.json();

        if (res && res.isConsented) {
          setIsOpenConsentScreen(false);
        }
      }
    );
  }, [sessionToken, setIsOpenConsentScreen]);

  const demoShown = useCallback(() => {
    if (!sessionToken) return;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", sessionToken);
    const requestOptions: RequestInit = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ fieldName: "isMyTimeDemo" }),
    };
    fetch(`${BACKEND_URL}/cookieConsent/enable`, requestOptions).then(
      async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const res = await response.json();
        if (res && res.status) {
          setInteractiveDemo(false);
        }
      }
    );
  }, [sessionToken]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vh",
        }}
      >
        <Spin fullscreen={true} tip="Loading..." size="large" />
      </div>
    );
  }

  if (isOpenConsentScreen) {
    return (
      <Modal
        data-testid="consentModal"
        contentSpacing={false}
        // description="Subtitle description text goes here"
        id="story-book-modal"
        onClose={() => {
          console.log();
        }}
        title="Use of Cookies"
        show={isOpenConsentScreen}
      >
        <ModalContent>
          <p>
            To use this application it is necessary to accept the use of third
            party cookies. Details can be found by clicking{" "}
            <a target="_blank" href="https://123x.dev/privacy/">
              here
            </a>
            .
          </p>
          <Button color={Button.colors.PRIMARY} onClick={agreeConsent}>
            I AGREE
          </Button>
        </ModalContent>
      </Modal>
    );
  }
  if (isOauthScreen) {
    return (
      <Modal
        data-testid="consentModal"
        contentSpacing={false}
        // description="Worklogs needs access to your monday.com data in order to enable time tracking across items, boards."
        id="story-book-modal"
        onClose={function noRefCheck() {}}
        title="Please grant access"
        show={isOauthScreen}
      >
        <ModalContent>
          <p>
            123x Timetracker needs access to your monday.com data in order to
            enable time tracking across items, boards
            {/* <a target="_blank" href="https://123x.dev/privacy/">
              here
            </a> */}
            .
          </p>
          <a
            href={`https://backend.worklogged.123x.dev/authorization?url=${
              window.location.href ?? ""
            }`}
          >
            click here to Grant Access 🤝
          </a>
        </ModalContent>
      </Modal>
    );
  }

  const items = [
    {
      key: "1",
      label: "Calendar",
      children: (
        <Calendar
          userId={userId}
          isViewOnly={isViewOnly}
          sessionToken={sessionToken}
          theme={theme}
        />
      ),
    },
    {
      key: "2",
      label: "TimeSheet",
      children: (
        <Timesheet
          userId={userId}
          isViewOnly={isViewOnly}
          sessionToken={sessionToken}
        />
      ),
    },
  ];

  if (isViewOnly) {
    return (
      <>
        Access to this page is restricted to authorized users only. If you
        believe this is an error, please reach out to the administrator for
        assistance.
      </>
    );
  }

  return (
    <>
      <Modal
        data-testid="consentModal"
        contentSpacing={false}
        description="To close the modal, simply click outside of it. If you wish to view again, click the link at the bottom of the page."
        id="story-book-modal"
        onClose={() => {
          setInteractiveDemo(false);
          demoShown();
        }}
        title="Interactive Demo"
        show={interactiveDemo}
        width={Modal.width.FULL_WIDTH}
      >
        <ModalContent>
          <div
            style={{
              position: "relative",
              height: "780px",
              width: "1030px",
              marginTop: "20px",
              border: "1px solid grey",
            }}
          >
            <iframe
              src="https://app.supademo.com/embed/clvze7vbv1f65kzemt577zedm"
              allow="clipboard-write"
              // frameborder="0"
              // webkitallowfullscreen="true"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </ModalContent>
      </Modal>
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              zIndexPopup: 10001,
            },
          },
          token: {
            fontFamily:
              "Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP,sans-serif",
          },
        }}
      >
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey="1"
          items={items}
        />
      </ConfigProvider>
      <div className={styles.footer}>
        <a onClick={() => setInteractiveDemo(true)}>
          Click here to open Interactive demo
        </a>
      </div>
      <div className={styles.footer}>
        Checkout this{" "}
        <a
          target="_blank"
          href="https://123x.dev/blog/worklogs-for-monday-dot-com/"
        >
          link
        </a>{" "}
        for more details about how to use this app.
      </div>
    </>
  );
}

export default App;
