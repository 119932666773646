import moment from "moment";
import { BACKEND_URL, MONDAY_API_VERSION } from "./constants";

import mondaySdk from "monday-sdk-js";
const monday = mondaySdk();

// UTC string format 'YYYY-MM-DD HH:mm:ss');
export function convertUTCToLocal(utc: string) {
  const stillUtc = moment.utc(utc, "YYYY-MM-DD HH:mm:ss");
  const local = moment(stillUtc).local();
  return local;
}

export function convertUTCToLocalWithDuration(utc: string, duration: number) {
  const stillUtc = moment
    .utc(utc, "YYYY-MM-DD HH:mm:ss")
    .add(duration, "seconds");
  const local = moment(stillUtc).local();
  return local;
}
// inp: 2024-03-04T12:34:56
export function convertLocalToUTC(local: Date) {
  return local.toISOString().slice(0, 19).replace("T", " "); //'YYYY-MM-DD HH:mm:ss')
}

export function formatDurationCustom(s: number) {
  const duration = moment.duration(s, "seconds");
  const formattedDuration = `${
    duration.hours() ? `${duration.hours()}h ` : ""
  }${duration.minutes()}m`;
  return formattedDuration;
}

export async function addWorklogHelper(
  args: {
    comment: string;
    startedAt: string;
    duration: number;
    isBillable: boolean;
    assigneeId: string;
    itemId: string;
  },
  sessionToken: string
) {
  if (!sessionToken || !args) {
    return;
  }
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", sessionToken);

    const raw = JSON.stringify({
      ...args,
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: headers,
      body: raw,
    };
    const response = await fetch(`${BACKEND_URL}/worklog`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.worklog;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function editWorklogHelper(
  worklog: {
    id: number;
    comment?: string;
    startedAt: string;
    duration: number;
    isBillable: boolean;
  },
  sessionToken: string
) {
  if (!sessionToken || !worklog) {
    return;
  }
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", sessionToken);

    const raw = JSON.stringify({
      comment: worklog.comment || "",
      startedAt: worklog.startedAt,
      duration: worklog.duration,
      isBillable: worklog.isBillable,
    });

    const requestOptions: RequestInit = {
      method: "PUT",
      headers: headers,
      body: raw,
    };
    const response = await fetch(
      `${BACKEND_URL}/worklog/${worklog.id}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteWorklogHelper(
  worklogId: number,
  sessionToken: string
) {
  if (!sessionToken || !worklogId) {
    return;
  }
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", sessionToken);

    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: headers,
    };
    const response = await fetch(
      `${BACKEND_URL}/worklog/${worklogId}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function fetchBoardlist(
  sessionToken: string
): Promise<{ label: string; value: string }[]> {
  // const str = `query GetBoardItems{
  //   boards(order_by: used_at, limit: 500) {
  //     id
  //     name
  //     type
  //     workspace {
  //       name
  //     }
  //   }
  // }`;
  const mainWorkspaceQuery = `query GetBoardItems{  
    boards(workspace_ids: [null], limit: 10000) {
      id
      workspace {
        name
      }
    }
  }`;
  // const p1 = monday.api(str, {
  //   apiVersion: MONDAY_API_VERSION,
  // });
  const p2 = monday.api(mainWorkspaceQuery, {
    apiVersion: MONDAY_API_VERSION,
  });

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", sessionToken);
  const requestOptions: RequestInit = {
    method: "GET",
    headers: headers,
  };
  const p3 = fetch(`${BACKEND_URL}/worklog/fetchboards`, requestOptions);

  const promiseResults = await Promise.allSettled([p3, p2]);
  if (promiseResults[0].status === "rejected") {
    throw new Error(`monday rejected: ${promiseResults[0].reason}`);
  }
  if (promiseResults[1].status === "rejected") {
    throw new Error(`mainWorkspaceQuery rejected: ${promiseResults[1].reason}`);
  }
  const boardList = (await promiseResults[0]?.value.json()) as {
    boards: {
      id: string;
      name: string;
      type: string;
      workspace: { name: string };
    }[];
  };
  const lst = boardList;

  const lst2 = promiseResults[1]?.value.data as {
    boards: {
      id: string;
      name: string;
      type: string;
      workspace: { name: string };
    }[];
  };
  // boardId => workspaceName
  const boardIdWorkspaceName = new Map<string, string>();
  lst2.boards.map((b) => {
    boardIdWorkspaceName.set(b.id, b.workspace.name);
  });
  const options: { value: string; label: string }[] = lst.boards
    .filter((b: { type: string }) => b.type === "board")
    .map((b: { id: string; name: string; workspace: { name: string } }) => {
      let workspaceName = b?.workspace?.name;
      if (!workspaceName) {
        // in OLD workspaces workspace.name is null, use lst2
        if (boardIdWorkspaceName.has(b.id)) {
          workspaceName = boardIdWorkspaceName.get(b.id) ?? "";
        } else {
          workspaceName = "";
        }
      }
      return {
        value: b.id,
        label: `${workspaceName} -> ${b?.name}`,
      };
    });
  return options;
}

export async function fetchItemList(
  boardId: string,
  sessionToken: string
): Promise<
  | {
      label: string;
      options: { value: string; label: string }[];
    }[]
  | []
> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", sessionToken);
  const requestOptions: RequestInit = {
    method: "GET",
    headers: headers,
  };
  const resp = await fetch(
    `${BACKEND_URL}/worklog/fetchItems/${boardId}`,
    requestOptions
  );
  const json = await resp.json();
  return json.items;
}

export type ItemDetail = {
  id: number;
  name: string;
  board: {
    name?: string;
    workspace: {
      name?: string;
      settings: {
        icon: {
          color: string | null;
          image: string | null;
        };
      };
    };
  };
  group: {
    title: string;
    color: string;
  };
};
export async function fetchItemDetails(
  itemIds: number[]
): Promise<ItemDetail[]> {
  if (!itemIds) return [];

  const str = `query {
  items(ids: [${itemIds.join(", ")}]) {
    id
    name
    board {
      name
      workspace {
        name
      	settings {
          icon {
            color
            image
          }
        }
      }
    }
    group {
      title
      color
    }
  }
}`;

  return new Promise((resolve, reject) => {
    monday
      .api(str, {
        apiVersion: MONDAY_API_VERSION,
      })
      .then((res: { data: any }) => {
        const lst = res?.data;
        resolve(lst.items);
      })
      .catch((err: any) => reject(err));
  });
}

export async function fetchGroupDetails(
  itemIds: number[]
): Promise<{ id: string; name: string; group: { color: string } }[]> {
  if (!itemIds) return [];

  const str = `query {
  items(ids: [${itemIds.join(", ")}]) {
    id
    name
    group {
      color
    }
  }
}`;

  return new Promise((resolve, reject) => {
    monday
      .api(str, {
        apiVersion: MONDAY_API_VERSION,
      })
      .then((res: { data: any }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const lst = res?.data;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        resolve(lst?.items);
      })
      .catch((err: any) => reject(err));
  });
}
