import { useCallback, useEffect, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Flex, Tooltip } from "monday-ui-react-core";
import {
  convertLocalToUTC,
  convertUTCToLocal,
  formatDurationCustom,
} from "../util/helper";
import { BACKEND_URL, Worklog } from "../util/constants";
import { endOfWeek, startOfWeek } from "date-fns";
import _ from "lodash";
import { DatePicker, Avatar, TimeRangePickerProps, Spin } from "antd";
import dayjs from "dayjs";
import styles from "./Timesheet.module.css";
import { PanelLeft } from "lucide-react";

const { RangePicker } = DatePicker;

export interface MondayItemDetail {
  id: string;
  name: string;
  board: {
    name: string;
    id: string;
    workspace: {
      name: string;
      settings: {
        icon: {
          color: string;
          image: string;
        };
      };
    };
  };
  group: {
    title: string;
    color: string;
    id: string;
  };
}

type TimeSheetEntry = {
  itemId: number;
  itemName: string;
  boardName: string;
  workspaceName: string;
  workspaceIconColor: string | null;
  workspaceIconImage: string | null;
  groupTitle: string;
  groupColor: string;
  Monday?: Worklog[];
  Tuesday?: Worklog[];
  Wednesday?: Worklog[];
  Thursday?: Worklog[];
  Friday?: Worklog[];
  Saturday?: Worklog[];
  Sunday?: Worklog[];
  total: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Timesheet(props: {
  userId: string | null;
  isViewOnly: boolean;
  sessionToken: string;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TimeSheetEntry[]>([]);
  const [calRange, setCalRange] = useState<{ startDate: Date; endDate: Date }>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
  });

  // UseEffects
  const fetchData = useCallback(
    async (
      sessionToken: string,
      calRange: { startDate: Date; endDate: Date }
    ) => {
      if (!calRange || !sessionToken) {
        return;
      }
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", sessionToken);
        const requestOptions: RequestInit = {
          method: "GET",
          headers: headers,
        };
        const response = await fetch(
          `${BACKEND_URL}/worklog/me?startDate=${convertLocalToUTC(
            calRange.startDate
          )}&endDate=${convertLocalToUTC(calRange.endDate)}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const res = await response.json();

        const temp = res.worklogs.map((w: { startedAt: string }) => {
          const day = convertUTCToLocal(w.startedAt).format("dddd");
          return {
            day: day,
            ...w,
          };
        });
        const groupItems = _.groupBy(temp, (v: { itemId: number }) => v.itemId);
        const itemIds = Object.keys(groupItems).map((i) => Number(i));
        if (itemIds.length) {
          const arr: TimeSheetEntry[] = [];
          for (const k in groupItems) {
            const v: MondayItemDetail = groupItems[k][0]
              .externalItem as unknown as MondayItemDetail;
            const curEntry = {
              itemId: Number(k),
              total: _.sum(
                groupItems[k].map((i: { duration: number }) => i.duration)
              ),
              ..._.groupBy(groupItems[k], (v: { day: string }) => v?.day),
              itemName: v?.name ?? "",
              boardName: v?.board?.name ?? "",
              workspaceName: v?.board?.workspace?.name ?? "",
              workspaceIconColor:
                v?.board?.workspace?.settings?.icon?.color ?? "",
              workspaceIconImage:
                v?.board?.workspace?.settings?.icon?.image ?? "",
              groupTitle: v?.group?.title ?? "",
              groupColor: v?.group?.color ?? "",
            };
            arr.push(curEntry);
          }
          setData(arr);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  useEffect(() => {
    fetchData(props.sessionToken, calRange).finally(() => setLoading(false));
  }, [props.sessionToken, calRange, fetchData]);

  const durationByDay = useMemo(() => {
    const ds = new Map<string, number>();

    data.map((tse) => {
      tse.Monday?.map((ws) =>
        ds.set("Monday", (ds.get("Monday") || 0) + ws.duration)
      );
      tse.Tuesday?.map((ws) =>
        ds.set("Tuesday", (ds.get("Tuesday") || 0) + ws.duration)
      );
      tse.Wednesday?.map((ws) =>
        ds.set("Wednesday", (ds.get("Wednesday") || 0) + ws.duration)
      );
      tse.Thursday?.map((ws) =>
        ds.set("Thursday", (ds.get("Thursday") || 0) + ws.duration)
      );
      tse.Friday?.map((ws) =>
        ds.set("Friday", (ds.get("Friday") || 0) + ws.duration)
      );
      tse.Saturday?.map((ws) =>
        ds.set("Saturday", (ds.get("Saturday") || 0) + ws.duration)
      );
      tse.Sunday?.map((ws) =>
        ds.set("Sunday", (ds.get("Sunday") || 0) + ws.duration)
      );
    });
    return ds;
  }, [data]);

  const columns = useMemo<MRT_ColumnDef<TimeSheetEntry>[]>(
    () => [
      {
        accessorFn: (row: { itemId: any }) => `${row.itemId}`,
        id: "itemId",
        header: "Item",
        size: 80,
        Cell: ({ row }: { row: any }) => (
          <Tooltip
            position={Tooltip.positions.RIGHT}
            content={row.original.itemName}
            modifiers={[
              {
                name: "preventOverflow",
                options: {
                  mainAxis: false,
                },
              },
              {
                name: "flip",
                options: {
                  fallbackPlacements: [],
                },
              },
            ]}
            withMaxWidth={true}
          >
            <Box>{row.original.itemName} </Box>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row: { groupTitle: any }) => `${row.groupTitle}`, //accessorFn used to join multiple data into a single cell
        id: "group",
        header: "Group",
        size: 80,
        Cell: ({
          renderedCellValue,
          row,
        }: {
          renderedCellValue: any;
          row: any;
        }) => (
          <Tooltip
            position={Tooltip.positions.LEFT}
            content={renderedCellValue}
            modifiers={[
              {
                name: "preventOverflow",
                options: {
                  mainAxis: false,
                },
              },
              {
                name: "flip",
                options: {
                  fallbackPlacements: [],
                },
              },
            ]}
            withMaxWidth={true}
          >
            <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
              <div
                className={styles.circle}
                style={{ backgroundColor: row.original.groupColor }}
              ></div>
              <div style={{ marginTop: "5px" }}>{renderedCellValue}</div>
            </Flex>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row: { boardName: any }) => `${row.boardName}`, //accessorFn used to join multiple data into a single cell
        id: "board",
        header: "Board",
        size: 120,
        Cell: ({ renderedCellValue }) => (
          <Tooltip
            position={Tooltip.positions.LEFT}
            content={renderedCellValue}
            modifiers={[
              {
                name: "preventOverflow",
                options: {
                  mainAxis: false,
                },
              },
              {
                name: "flip",
                options: {
                  fallbackPlacements: [],
                },
              },
            ]}
            withMaxWidth={true}
          >
            <Flex direction={Flex.directions.ROW}>
              <div style={{ marginRight: "5px" }}>
                <PanelLeft size={14} />
              </div>
              {renderedCellValue}
            </Flex>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row: { workspaceName: any }) => `${row.workspaceName}`, //accessorFn used to join multiple data into a single cell
        id: "workspace",
        header: "Workspace",
        size: 120,
        Cell: ({ row, renderedCellValue }) => (
          <Tooltip
            position={Tooltip.positions.LEFT}
            content={renderedCellValue}
            modifiers={[
              {
                name: "preventOverflow",
                options: {
                  mainAxis: false,
                },
              },
              {
                name: "flip",
                options: {
                  fallbackPlacements: [],
                },
              },
            ]}
            withMaxWidth={true}
          >
            <Flex direction={Flex.directions.ROW}>
              {row.original.workspaceIconImage && (
                <Avatar
                  size={20}
                  style={{
                    backgroundColor: row.original.workspaceIconColor || "#fff",
                    marginRight: "5px",
                  }}
                  src={
                    <img src={row.original.workspaceIconImage} alt="avatar" />
                  }
                />
              )}
              {!row.original.workspaceIconImage &&
                row.original.workspaceIconColor && (
                  <Avatar
                    size={20}
                    style={{
                      backgroundColor:
                        row.original.workspaceIconColor || "#fde3cf",
                      marginRight: "5px",
                    }}
                  >
                    {row.original?.workspaceName[0] ?? ""}
                  </Avatar>
                )}
              <div style={{ marginTop: "5px" }}>{renderedCellValue}</div>
            </Flex>
          </Tooltip>
        ),
      },
      {
        id: "Monday",
        header: "Monday",
        size: 80,
        Cell: ({ row }: { row: any }) => {
          const durations = row.original?.Monday?.map(
            (w: { duration: any }) => w.duration
          );
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                {durations && formatDurationCustom(_.sum(durations))}
              </Flex>
            </>
          );
        },
        Footer: () => {
          if (data.length === 0) return <></>;
          return (
            <div className={styles.totalDurationWithtopborder}>
              {formatDurationCustom(durationByDay.get("Monday") || 0)}
            </div>
          );
        },
      },
      {
        id: "Tuesday",
        header: "Tuesday",
        size: 80,
        Cell: ({ row }: { row: any }) => {
          const durations = row.original?.Tuesday?.map(
            (w: { duration: any }) => w.duration
          );
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                {durations && formatDurationCustom(_.sum(durations))}
              </Flex>
            </>
          );
        },
        Footer: () => {
          if (data.length === 0) return <></>;
          return (
            <div className={styles.totalDurationWithtopborder}>
              {formatDurationCustom(durationByDay.get("Tuesday") || 0)}
            </div>
          );
        },
      },
      {
        id: "Wednesday",
        header: "Wednesday",
        size: 80,
        Cell: ({ row }: { row: any }) => {
          const durations = row.original?.Wednesday?.map(
            (w: { duration: any }) => w.duration
          );
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                {durations && formatDurationCustom(_.sum(durations))}
              </Flex>
            </>
          );
        },
        Footer: () => {
          if (data.length === 0) return <></>;
          return (
            <div className={styles.totalDurationWithtopborder}>
              {formatDurationCustom(durationByDay.get("Wednesday") || 0)}
            </div>
          );
        },
      },
      {
        id: "Thursday",
        header: "Thursday",
        size: 80,
        Cell: ({ row }: { row: any }) => {
          const durations = row.original?.Thursday?.map(
            (w: { duration: any }) => w.duration
          );
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                {durations && formatDurationCustom(_.sum(durations))}
              </Flex>
            </>
          );
        },
        Footer: () => {
          if (data.length === 0) return <></>;
          return (
            <div className={styles.totalDurationWithtopborder}>
              {formatDurationCustom(durationByDay.get("Thursday") || 0)}
            </div>
          );
        },
      },
      {
        id: "Friday",
        header: "Friday",
        size: 80,
        Cell: ({ row }: { row: any }) => {
          const durations = row.original?.Friday?.map(
            (w: { duration: any }) => w.duration
          );
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                {durations && formatDurationCustom(_.sum(durations))}
              </Flex>
            </>
          );
        },
        Footer: () => {
          if (data.length === 0) return <></>;
          return (
            <div className={styles.totalDurationWithtopborder}>
              {formatDurationCustom(durationByDay.get("Friday") || 0)}
            </div>
          );
        },
      },
      {
        id: "Saturday",
        header: "Saturday",
        size: 80,
        Cell: ({ row }: { row: any }) => {
          const durations = row.original?.Saturday?.map(
            (w: { duration: any }) => w.duration
          );
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                {durations && formatDurationCustom(_.sum(durations))}
              </Flex>
            </>
          );
        },
        Footer: () => {
          if (data.length === 0) return <></>;
          return (
            <div className={styles.totalDurationWithtopborder}>
              {formatDurationCustom(durationByDay.get("Saturday") || 0)}
            </div>
          );
        },
      },
      {
        id: "Sunday",
        header: "Sunday",
        size: 80,
        Cell: ({ row }: { row: any }) => {
          const durations = row.original?.Sunday?.map(
            (w: { duration: any }) => w.duration
          );
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                {durations && formatDurationCustom(_.sum(durations))}
              </Flex>
            </>
          );
        },
        enableHiding: true,
        Footer: () => {
          if (data.length === 0) return <></>;
          return (
            <div className={styles.totalDurationWithtopborder}>
              {formatDurationCustom(durationByDay.get("Sunday") || 0)}
            </div>
          );
        },
      },
      {
        header: "Total",
        Cell: ({ row }: { row: any }) => {
          const duration = row.original?.total;
          return (
            <>
              <Flex gap={Flex.gaps.XS} align={Flex.align.CENTER}>
                <div className={styles.totalDuration}>
                  {duration && formatDurationCustom(duration)}
                </div>
              </Flex>
            </>
          );
        },

        enableHiding: false,
      },
    ],
    [data.length, durationByDay]
  );

  const rangePresets: TimeRangePickerProps["presets"] = useMemo(
    () => [
      {
        label: "Current Week",
        value: [dayjs().startOf("week"), dayjs().endOf("week")],
      },
      {
        label: "Last Week",
        value: [
          dayjs().subtract(1, "week").startOf("week"),
          dayjs().subtract(1, "week").endOf("week"),
        ],
      },
      {
        label: "2 Weeks before",
        value: [
          dayjs().subtract(2, "week").startOf("week"),
          dayjs().subtract(2, "week").endOf("week"),
        ],
      },
      {
        label: "3 Weeks before",
        value: [
          dayjs().subtract(3, "week").startOf("week"),
          dayjs().subtract(3, "week").endOf("week"),
        ],
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    initialState: {
      density: "compact",
    },
    muiToolbarAlertBannerChipProps: { color: "primary" },
    muiTableContainerProps: { sx: { maxHeight: 700 } },
    enablePagination: false,
    enableColumnOrdering: true,
    enableColumnDragging: false,
    renderTopToolbarCustomActions: () => (
      <Flex
        gap={Flex.gaps.MEDIUM}
        align={Flex.align.BASELINE}
        justify={Flex.justify.SPACE_AROUND}
      >
        <RangePicker
          presets={rangePresets}
          value={[dayjs(calRange.startDate), dayjs(calRange.endDate)]}
          onChange={(lst: any[] | null) => {
            if (lst && lst.length === 2) {
              const startDate = lst[0]?.toDate();
              const endDate = lst[1]?.toDate();
              if (startDate && endDate) {
                setCalRange({ startDate, endDate });
              }
            }
          }}
        />
      </Flex>
    ),
  });

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vh",
        }}
      >
        <Spin fullscreen={true} tip="Loading..." size="large" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Box padding={Box.paddings.MEDIUM}>
        <MaterialReactTable table={table} />
      </Box>
    </div>
  );
}
