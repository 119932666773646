import { Event } from "react-big-calendar";
import {
  formatDurationCustom,
  convertUTCToLocal,
  convertUTCToLocalWithDuration,
} from "./helper";

export const MONDAY_API_VERSION = "2024-07";
export const BACKEND_URL = "https://backend.worklogged.123x.dev";

export interface Worklog {
  id: number;
  comment?: string;
  startedAt: string;
  duration: number;
  isBillable: boolean;
  createdAt: Date;
  editedAt: Date;
  itemId: string;
  assigneeId: string;
  accountId: string;
  groupColor?: string;
  itemName?: string;
  externalItem?: {
    id: string;
    name: string;
    board: {
      name: string;
      id: string;
      workspace: {
        name: string;
        settings: {
          icon: {
            color: string;
            image: string;
          };
        };
      };
    };
    group: {
      title: string;
      color: string;
      id: string;
    };
  };
}

export interface WorklogEvent extends Event {
  id?: number;
  title?: React.ReactNode | undefined;
  start?: Date | undefined;
  end?: Date | undefined;
  resource: Worklog;
}

export function worklogToEvent(worklog: Worklog): WorklogEvent {
  return {
    id: worklog.id,
    title:
      (worklog?.comment ? worklog?.comment + " for " : "") +
      formatDurationCustom(worklog.duration), // TODO: fetch using graphql
    start: convertUTCToLocal(worklog.startedAt).toDate(),
    end: convertUTCToLocalWithDuration(
      worklog.startedAt,
      worklog.duration
    ).toDate(),
    resource: worklog,
  };
}

export type State = {
  worklogs: Worklog[];
};

export enum ActionType {
  WL_INIT = 1,
  WL_ADD,
  WL_EDIT,
  WL_DELETE,
}

export type Action =
  | { type: ActionType.WL_INIT; worklogs: Worklog[] }
  | { type: ActionType.WL_ADD; worklog: Worklog }
  | { type: ActionType.WL_EDIT; worklog: Worklog }
  | { type: ActionType.WL_DELETE; worklogId: number };
