/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "./AddTime.module.css";
import { DatePicker, Input, TimePicker, TimePickerProps } from "antd";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalContent,
  Toggle,
} from "monday-ui-react-core";
import {
  Dispatch,
  ReducerAction,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  addWorklogHelper,
  convertLocalToUTC,
  convertUTCToLocal,
  deleteWorklogHelper,
  editWorklogHelper,
  fetchBoardlist,
  fetchItemList,
} from "../util/helper";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import { Worklog, State, Action, ActionType } from "../util/constants";
import { SlotInfo } from "react-big-calendar";
import moment from "moment";
dayjs.extend(durationPlugin);

import Select, {
  GroupHeadingProps,
  MenuListProps,
  components,
  StylesConfig,
} from "react-select";

const HideGroupHeading = (props: GroupHeadingProps) => {
  // if (!document) return;
  return (
    <div
      className="collapse-group-heading"
      onClick={() => {
        const element = document?.querySelector(`#${props.id}`);
        if (element) {
          const parentElement = element.parentElement;
          if (parentElement) {
            const grandParentElement = parentElement.parentElement;
            if (grandParentElement) {
              grandParentElement.classList.toggle("collapsed-group");
            }
          }
        }
      }}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const HideGroupMenuList = (props: MenuListProps) => {
  const new_props = {
    ...props,
    children: Array.isArray(props.children)
      ? props.children.map((c, idx) =>
          idx === 0
            ? c
            : { ...c, props: { ...c.props, className: "collapsed-group" } }
        )
      : props.children,
  };

  return <components.MenuList {...new_props} />;
};

const durationToMoment = (duration: durationPlugin.Duration) => {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const val = dayjs().set("hour", hours).set("minute", minutes);
  return val;
};
const DateJSToDuration = (dateJsTime: dayjs.Dayjs) => {
  const parsedTime = dayjs(dateJsTime, { format: "hh:mm A" });

  // Create a dayjs duration from the parsed time
  const duration = dayjs.duration({
    hours: parsedTime.hour(),
    minutes: parsedTime.minute(),
  });

  return duration;
};

const getDurationSeconds = (
  worklog: Worklog | undefined,
  selectedSlot: SlotInfo | undefined
) => {
  const defaultDuration = 30 * 60;
  if (!worklog && !selectedSlot) return defaultDuration;
  if (worklog)
    return worklog?.duration == 0 || worklog?.duration === 86400
      ? defaultDuration
      : worklog?.duration;
  if (selectedSlot) {
    const cur = moment(selectedSlot.end).diff(
      moment(selectedSlot.start),
      "seconds"
    );
    return cur === 0 || cur >= 86400 ? defaultDuration : cur;
  }

  return defaultDuration;
};

export default function AddTime(props: {
  worklog?: Worklog;
  selectedSlot?: SlotInfo;
  dispatch: Dispatch<ReducerAction<(s: State, a: Action) => State>>;
  isModalOpen: boolean;
  onCloseModal: () => void;
  sessionToken: string;
  userId: string;
  theme: string;
}) {
  // HOOKS
  const localTime = useMemo(() => {
    if (!props.worklog?.startedAt) {
      return null;
    }
    return convertUTCToLocal(props?.worklog?.startedAt);
  }, [props.worklog]);
  const isWorklogPresent = useMemo(
    () => localTime && localTime?.isValid(),
    [localTime]
  );

  const [loadingBoards, setLoadingBoards] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [worklogDate, setWorklogDate] = useState<dayjs.Dayjs>(
    isWorklogPresent
      ? dayjs(localTime?.format("YYYY-MM-DD"))
      : props.selectedSlot
      ? dayjs(props.selectedSlot.start)
      : dayjs()
  );
  const [fromToToggleValue, setFromToToggleValue] = useState<boolean>(
    isWorklogPresent || props.selectedSlot ? true : false
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fromTime, setFromTime] = useState<dayjs.Dayjs>(
    isWorklogPresent
      ? dayjs(localTime?.format("YYYY-MM-DDTHH:mm:ss"))
      : props.selectedSlot
      ? dayjs(props.selectedSlot.start)
      : dayjs()
  );
  const [duration, setDuration] = useState<durationPlugin.Duration>(
    dayjs.duration(
      getDurationSeconds(props?.worklog, props?.selectedSlot),
      "seconds"
    )
  );
  const [comment, setComment] = useState<string>(
    props?.worklog?.comment ? props.worklog.comment : ""
  );
  const [isBillable, setIsBillable] = useState<boolean>(
    props?.worklog?.isBillable ? props.worklog.isBillable : true
  );
  const [boardList, setBoardList] = useState<
    { label: string; value: string }[]
  >([]);
  const [boardListOption, setBoardListOption] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [itemList, setItemList] = useState<
    { label: string; options: { label: string; value: string }[] }[]
  >([]);

  const [itemListOption, setItemListOption] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [fromTimeOpen, setFromTimeOpen] = useState<boolean>(false);
  const [toTimeOpen, setToTimeOpen] = useState<boolean>(false);
  const [durationOpen, setDurationOpen] = useState<boolean>(false);

  // useEffect

  useEffect(() => {
    if (!props.selectedSlot) return;
    setLoadingBoards(true);
    fetchBoardlist(props.sessionToken)
      .then((boards) => setBoardList(boards))
      .finally(() => setLoadingBoards(false));
  }, [props.selectedSlot, props.sessionToken]);

  useEffect(() => {
    if (!boardListOption) return;
    setLoadingItems(true);
    fetchItemList(boardListOption.value, props.sessionToken)
      .then((items) => setItemList(items))
      .finally(() => setLoadingItems(false));
  }, [boardListOption, props.sessionToken]);

  // useCallback
  const onDateChange = useCallback((e: any) => {
    setWorklogDate(e);
  }, []);

  const onClickDelete = useCallback(() => {
    const worklogId = props.worklog && props.worklog.id;
    if (worklogId) {
      deleteWorklogHelper(worklogId, props.sessionToken).then(() =>
        props.dispatch({
          type: ActionType.WL_DELETE,
          worklogId: worklogId,
        })
      );
    }
    props.onCloseModal();
  }, [props]);

  // const result = {
  //   startedAt: `${worklogDate.toISOString().slice(0, 19).split("T")[0]} ${
  //     fromTime.toISOString().slice(0, 19).split("T")[1]
  //   }`,
  //   duration: duration.as("second"),
  //   comment: comment,
  //   isBillable: isBillable,
  // };
  // console.log("result", JSON.stringify(result));

  const onClickSave = useCallback(() => {
    if (isWorklogPresent) {
      // EDIT
      const newDate = dayjs()
        .set("year", worklogDate.get("year"))
        .set("month", worklogDate.get("month"))
        .set("date", worklogDate.get("date"))
        .set("hour", fromTime.get("hour"))
        .set("minute", fromTime.get("minute"))
        .set("second", fromTime.get("second"));
      const newWorklog = {
        ...props.worklog,
        id: props?.worklog?.id as number,
        startedAt: convertLocalToUTC(newDate.toDate()),
        duration: duration.as("second"),
        comment: comment,
        isBillable: isBillable,
      };
      editWorklogHelper(newWorklog, props.sessionToken).then((data) =>
        props.dispatch({
          type: ActionType.WL_EDIT,
          worklog: {
            ...data.data,
            groupColor: props.worklog?.groupColor,
            itemName: props.worklog?.itemName,
          },
        })
      );
    } else {
      // SAVE
      if (!itemListOption) return;
      const wld = worklogDate.format("YYYY-MM-DD");
      const ftd = convertLocalToUTC(fromTime.toDate());
      const newWorklog = {
        startedAt: `${wld} ${ftd.split(" ")[1]}`,
        duration: duration.as("second"),
        comment: comment,
        isBillable: isBillable,
        itemId: itemListOption?.value,
        assigneeId: props.userId as string,
      };
      addWorklogHelper(newWorklog, props.sessionToken).then((data) =>
        props.dispatch({ type: ActionType.WL_ADD, worklog: data as Worklog })
      );
    }
    props.onCloseModal();
  }, [
    isWorklogPresent,
    props,
    worklogDate,
    fromTime,
    duration,
    comment,
    isBillable,
    itemListOption,
  ]);

  const onClickCancel = () => {
    props.onCloseModal();
  };

  const getProperty = useCallback(
    (varName: string): string => {
      if (!props.theme) return "#111";
      const bodyStyles = window.getComputedStyle(document.body);
      const val = bodyStyles.getPropertyValue(varName);
      return val;
    },
    [props.theme]
  );
  const primaryTextColor = useMemo(
    () => getProperty("--primary-text-color"),
    [getProperty]
  );

  const textColorOnPrimary = useMemo(
    () => getProperty("--text-color-on-primary"),
    [getProperty]
  );

  const primaryBgColor = useMemo(
    () => getProperty("--primary-background-color"),
    [getProperty]
  );

  const primarySelectedColor = useMemo(
    () => getProperty("--primary-selected-color"),
    [getProperty]
  );

  const primaryHoverColor = useMemo(
    () => getProperty("--primary-background-hover-color"),
    [getProperty]
  );

  const borderLayoutColor = useMemo(
    () => getProperty("--layout-border-color"),
    [getProperty]
  );

  const colourStyles: StylesConfig<any> = {
    control: (styles): any => ({
      ...styles,
      backgroundColor: primaryBgColor,
      borderRadius: "4px",
      borderColor: borderLayoutColor,
    }),
    option: (styles: any, { isDisabled, isSelected }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...styles,
        backgroundColor: isSelected ? primarySelectedColor : primaryBgColor,
        color: primaryTextColor,
        cursor: isDisabled ? "not-allowed" : "default",
        borderRadius: "4px",
        borderColor: borderLayoutColor,

        ":hover": {
          ...styles[":hover"],
          backgroundColor: isSelected
            ? primarySelectedColor
            : primaryHoverColor,
        },
        ":active": {
          ...styles[":active"],
          color: textColorOnPrimary,
          backgroundColor: primarySelectedColor,
        },
      };
    },
    input: (styles: any) => ({
      ...styles,
      color: primaryTextColor,
      borderRadius: "4px",
      borderColor: borderLayoutColor,
    }),
    placeholder: (styles: any) => ({ ...styles, color: primaryTextColor }),
    singleValue: (styles) => ({ ...styles, color: primaryTextColor }),
    menuList: (styles: any) => ({
      ...styles,
      zIndex: 10000,
      borderRadius: "4px",
      borderColor: borderLayoutColor,
    }),
    menuPortal: (styles: any) => ({
      ...styles,
      zIndex: 10000,
      borderRadius: "4px",
      borderColor: borderLayoutColor,
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 10000,
      borderRadius: "4px",
      borderColor: borderLayoutColor,
    }),
  };

  const toTime = fromTime.add(duration);

  const onFromTimeChange: TimePickerProps["onChange"] = (time) => {
    setDuration(dayjs.duration(toTime.diff(time)));
    setFromTime(time);
  };

  const onToTimeChange: TimePickerProps["onChange"] = (time) => {
    setDuration(dayjs.duration(time.diff(fromTime)));
  };

  const onDurationChange: TimePickerProps["onChange"] = (time) => {
    setDuration(DateJSToDuration(time));
  };

  // RETURN
  return (
    <Modal
      data-testid="editaddModal"
      contentSpacing
      id="story-book-modal"
      onClose={props.onCloseModal}
      title={isWorklogPresent ? "Edit Time" : "Add Time"}
      // triggerElement={null}
      show={props.isModalOpen}
    >
      <ModalContent>
        <div className={styles.topContainer}>
          <div className={styles.gridContainer}>
            {!isWorklogPresent && (
              <div className={styles.workItem}>
                {/* <Dropdown
                  options={boardList}
                  className={
                    "dropdown-stories-styles_with-chips " +
                    styles.workItemDropDown
                  }
                  placeholder="Select a board to browse items"
                  value={boardListOption}
                  onOptionSelect={(
                    opt: SetStateAction<{
                      label: string;
                      value: string;
                    } | null>
                  ) => setBoardListOption(opt)}
                /> */}
                <div className={styles.workItemDropDown}>
                  <Select
                    isLoading={loadingBoards}
                    loadingMessage={() => "loading boards..."}
                    className="basic-single"
                    styles={colourStyles}
                    placeholder="Select a board to browse items"
                    options={boardList}
                    onChange={(
                      opt: SetStateAction<{
                        label: string;
                        value: string;
                      } | null>
                    ) => setBoardListOption(opt)}
                    isMulti={false}
                    blurInputOnSelect={true}
                    closeMenuOnSelect={true}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: primaryBgColor,
                        neutral0: primaryBgColor,
                        neutral5: primaryBgColor,
                        neutral10: primaryBgColor,
                      },
                    })}
                  />
                </div>
                {boardListOption && (
                  <Select
                    isLoading={loadingItems}
                    loadingMessage={() => "loading items of board..."}
                    className="basic-single"
                    styles={colourStyles}
                    placeholder={"Select an Item"}
                    options={itemList}
                    onChange={(opt: any) => setItemListOption(opt)}
                    isMulti={false}
                    blurInputOnSelect={true}
                    closeMenuOnSelect={true}
                    components={{
                      GroupHeading: HideGroupHeading,
                      MenuList: HideGroupMenuList,
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: primaryBgColor,
                        neutral0: primaryBgColor,
                        neutral5: primaryBgColor,
                        neutral10: primaryBgColor,
                      },
                    })}
                  />
                )}
              </div>
            )}
            {isWorklogPresent && (
              <div className={styles.title}>{props.worklog?.itemName}</div>
            )}
            <div className={styles.date}>
              <DatePicker
                onChange={onDateChange}
                value={worklogDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode as HTMLElement;
                }}
                allowClear={false}
              />
            </div>
            <div className={styles.fromToToggle}>
              <Toggle
                offOverrideText="TimeFrame"
                onOverrideText=""
                isSelected={fromToToggleValue}
                onChange={(e: boolean) => setFromToToggleValue(e)}
              />
            </div>
            {fromToToggleValue && (
              <div className={styles.fromTo}>
                <Flex direction={Flex.directions.ROW}>
                  <Flex
                    align={Flex.align.START}
                    direction={Flex.directions.COLUMN}
                  >
                    <Box>From</Box>
                    <Box marginTop={Box.marginTops.SMALL}>
                      <TimePicker
                        format={"h:mm a"}
                        onChange={onFromTimeChange}
                        changeOnScroll
                        needConfirm={true}
                        onOk={() => {
                          setFromTimeOpen(false);
                        }}
                        onMouseDown={() => setFromTimeOpen(true)}
                        showNow={true}
                        defaultValue={fromTime}
                        open={fromTimeOpen}
                        allowClear={false}
                      />
                    </Box>
                  </Flex>
                  <Flex
                    align={Flex.align.START}
                    direction={Flex.directions.COLUMN}
                  >
                    <Box marginStart={Box.marginStarts.MEDIUM}>To</Box>
                    <Box
                      marginTop={Box.marginTops.SMALL}
                      marginStart={Box.marginStarts.MEDIUM}
                    >
                      <TimePicker
                        format={"h:mm a"}
                        onChange={onToTimeChange}
                        value={toTime}
                        changeOnScroll
                        needConfirm={true}
                        onOk={() => {
                          setToTimeOpen(false);
                        }}
                        onMouseDown={() => setToTimeOpen(true)}
                        showNow={true}
                        defaultValue={toTime}
                        open={toTimeOpen}
                        allowClear={false}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </div>
            )}
            <div className={styles.duration}>
              <Flex align={Flex.align.START} direction={Flex.directions.COLUMN}>
                <Box marginTop={Box.marginTops.MEDIUM}>Duration</Box>
                <Box
                  marginTop={Box.marginTops.SMALL}
                  marginBottom={Box.marginBottoms.MEDIUM}
                >
                  <TimePicker
                    onChange={onDurationChange}
                    format={"HH:mm"}
                    changeOnScroll
                    needConfirm={true}
                    onOk={() => {
                      setDurationOpen(false);
                    }}
                    onMouseDown={() => setDurationOpen(true)}
                    showNow={false}
                    defaultValue={durationToMoment(duration)}
                    value={durationToMoment(duration)}
                    open={durationOpen}
                    allowClear={false}
                  />
                </Box>
              </Flex>
            </div>
            <div className={styles.quickTime}>
              <ButtonGroup
                onSelect={(e) =>
                  setDuration(dayjs.duration({ minutes: Number(e) }))
                }
                options={[
                  {
                    text: "0.5h",
                    value: 30,
                  },
                  {
                    text: "1h",
                    value: 1 * 60,
                  },
                  {
                    text: "2h",
                    value: 2 * 60,
                  },
                  {
                    text: "4h",
                    value: 4 * 60,
                  },
                  {
                    text: "8h",
                    value: 8 * 60,
                  },
                ]}
                value={duration?.as("minutes")}
              />
            </div>
            <div className={styles.comment}>
              <Flex align={Flex.align.START} direction={Flex.directions.COLUMN}>
                <Box>Comment</Box>
                <Box marginTop={Box.marginTops.SMALL}>
                  <Input
                    type="text"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                  ></Input>
                </Box>
              </Flex>
            </div>
            <div className={styles.customFields}>
              <Toggle
                offOverrideText="Billable"
                onOverrideText=""
                isSelected={isBillable}
                onChange={(e: boolean) => setIsBillable(e)}
              />
            </div>
          </div>
        </div>
        <Box marginTop={Box.marginTops.LARGE}>
          <Flex
            align={Flex.align.END}
            gap={Flex.gaps.SMALL}
            justify={Flex.justify.END}
          >
            {isWorklogPresent && (
              <Button
                style={{ marginRight: "270px" }}
                color={Button.colors.NEGATIVE}
                onClick={onClickDelete}
              >
                Delete
              </Button>
            )}
            <Button kind={Button.kinds.SECONDARY} onClick={onClickCancel}>
              Cancel
            </Button>
            <Button
              color={Button.colors.PRIMARY}
              onClick={onClickSave}
              disabled={
                (!isWorklogPresent && !itemListOption) ||
                duration.as("second") === 0
              }
            >
              {isWorklogPresent ? "Save" : "Add"}
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
}
