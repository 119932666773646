/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import "monday-ui-react-core/tokens";
import {
  Calendar,
  SlotInfo,
  View,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import withDragAndDrop, {
  EventInteractionArgs,
} from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

// import "react-datepicker/dist/react-datepicker.css";
import styles from "./MyCalendar.module.css";
import {
  convertLocalToUTC,
  convertUTCToLocal,
  editWorklogHelper,
  formatDurationCustom,
} from "../util/helper";
import {
  Action,
  ActionType,
  BACKEND_URL,
  State,
  Worklog,
  WorklogEvent,
  worklogToEvent,
} from "../util/constants";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import AddTime from "./AddTime";
import { Flex, Tooltip } from "monday-ui-react-core";
import { Avatar, Popover, Space, Spin } from "antd";
import dayjs from "dayjs";
import { PanelLeft } from "lucide-react";

const localizer = momentLocalizer(moment);
const allViews: View[] = ["month", "week", "day"];

const DragAndDropCalendar = withDragAndDrop<WorklogEvent>(Calendar);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.WL_INIT:
      return {
        ...state,
        worklogs: action.worklogs,
      };
    case ActionType.WL_ADD:
      return {
        ...state,
        worklogs: [...state.worklogs, action.worklog],
      };
    case ActionType.WL_EDIT:
      return {
        ...state,
        worklogs: [
          ...state.worklogs.filter((w) => w.id !== action.worklog.id),
          action.worklog,
        ],
      };
    case ActionType.WL_DELETE:
      return {
        ...state,
        worklogs: state.worklogs.filter((w) => w.id !== action.worklogId),
      };

    default:
      throw Error("Unknown action.");
  }
}

export default function MyCalendar(props: {
  userId: string | null;
  isViewOnly: boolean;
  sessionToken: string;
  theme: string;
}) {
  // REDUCER
  const [state, dispatch] = useReducer<(s: State, a: Action) => State>(
    reducer,
    { worklogs: [] }
  );

  // STATES
  const [calRange, setCalRange] = useState<{ startDate: Date; endDate: Date }>({
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
  });
  const [currentNavDate, setCurrentNavDate] = useState(new Date());
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);

  const [_selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<SlotInfo | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<WorklogEvent | null>(null);
  const [selected] = useState<WorklogEvent | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // useEffects
  useEffect(() => {
    const fetchData = async () => {
      if (!calRange || !props.sessionToken) {
        return;
      }
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", props.sessionToken);
        const requestOptions: RequestInit = {
          method: "GET",
          headers: headers,
        };
        const response = await fetch(
          `${BACKEND_URL}/worklog/me?startDate=${convertLocalToUTC(
            calRange.startDate
          )}&endDate=${convertLocalToUTC(calRange.endDate)}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        dispatch({ type: ActionType.WL_INIT, worklogs: data.worklogs });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData().finally(() => setLoading(false));
  }, [calRange.startDate, calRange.endDate, props.sessionToken, calRange]);

  const editWorklog = useCallback(
    async (worklog: Worklog) => {
      dispatch({ type: ActionType.WL_DELETE, worklogId: worklog.id });
      const data = await editWorklogHelper(worklog, props.sessionToken);
      dispatch({ type: ActionType.WL_ADD, worklog: data.data });
      // dispatch({ type: ActionType.WL_EDIT, worklog: data.data });
    },
    [props.sessionToken]
  );

  // useMEMO and useCALLBACK
  const events = useMemo(() => {
    if (!state.worklogs) {
      return [];
    }
    const es = state.worklogs.map((w) => worklogToEvent(w));
    return es;
  }, [state.worklogs]);

  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    []
  );

  const closeModal = () => {
    setModalAddIsOpen(false);
    setSelectedDate(null);
  };

  const closeEditModal = () => {
    setSelectedEvent(null);
    setModalEditIsOpen(false);
  };

  const addEvent = (slotInfo: SlotInfo) => {
    setSelectedSlot(slotInfo);
    setModalAddIsOpen(true);
  };

  const handleSelectEvent = (calEvent: WorklogEvent) => {
    setSelectedEvent(calEvent);
    setModalEditIsOpen(true);
  };

  const handleEventDrop = useCallback(
    (args: EventInteractionArgs<WorklogEvent>) => {
      const { start, end, event } = args;
      const newWorklog = {
        ...event.resource,
        startedAt: convertLocalToUTC(new Date(start)),
        duration: moment(end).diff(moment(start), "seconds"),
      };
      editWorklog(newWorklog).catch((error) => console.error(error));
    },
    [editWorklog]
  );

  const handleEventResize = useCallback(
    (args: EventInteractionArgs<WorklogEvent>) => {
      const { start, end, event } = args;
      const newWorklog = {
        ...event.resource,
        startedAt: convertLocalToUTC(new Date(start)),
        duration: moment(end).diff(moment(start), "seconds"),
      };
      editWorklog(newWorklog).catch((error) => console.error(error));
    },
    [editWorklog]
  );

  const handleNavigate = useCallback((date: Date, view: View) => {
    setCurrentNavDate(date);
    switch (view) {
      case "month":
        setCalRange({
          startDate: startOfMonth(date),
          endDate: endOfMonth(date),
        });
        return;
      case "week":
      case "work_week":
        setCalRange({ startDate: startOfWeek(date), endDate: endOfWeek(date) });
        return;
      case "day":
        setCalRange({ startDate: startOfDay(date), endDate: endOfDay(date) });
        return;
    }
  }, []);

  const handleView = useCallback(
    (view: View) => {
      switch (view) {
        case "month":
          setCalRange({
            startDate: startOfMonth(currentNavDate),
            endDate: endOfMonth(currentNavDate),
          });
          return;
        case "week":
        case "work_week":
          setCalRange({
            startDate: startOfWeek(currentNavDate),
            endDate: endOfWeek(currentNavDate),
          });
          return;
        case "day":
          setCalRange({
            startDate: startOfDay(currentNavDate),
            endDate: endOfDay(currentNavDate),
          });
          return;
      }
    },
    [currentNavDate]
  );

  const durationByDate = useMemo(() => {
    const map = new Map<string, number>();
    state.worklogs.map((w) => {
      const key = convertUTCToLocal(w.startedAt).format("YYYY-MM-DD");
      const val = map.get(key);
      if (val) {
        map.set(key, val + w.duration);
      } else {
        map.set(key, w.duration);
      }
    });
    return map;
  }, [state.worklogs]);

  const components = useMemo(() => {
    return {
      week: {
        header: (args: { date: Date }) => {
          const mdate = moment(args.date);
          const dateDuration = durationByDate.get(mdate.format("YYYY-MM-DD"));
          return (
            <Flex
              gap={Flex.gaps.MEDIUM}
              align={Flex.align.CENTER}
              justify={Flex.justify.SPACE_BETWEEN}
            >
              <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.NONE}>
                <div className={styles.weekDayFormat}>{mdate.format("DD")}</div>
                <div className={styles.weekDateFormat}>
                  {mdate.format(" ddd")}
                </div>
              </Flex>
              {dateDuration && (
                <span className={styles.weekDuration}>
                  {formatDurationCustom(dateDuration || 0)}
                </span>
              )}
            </Flex>
          );
        },
      },
      month: {
        dateHeader: (args: { date: Date }) => {
          const mdate = moment(args.date);
          const dateDuration = durationByDate.get(mdate.format("YYYY-MM-DD"));
          return (
            <Flex
              gap={Flex.gaps.MEDIUM}
              align={Flex.align.CENTER}
              justify={Flex.justify.SPACE_AROUND}
            >
              <Flex direction={Flex.directions.COLUMN}>
                <div className={styles.weekDayFormat}>{mdate.format("DD")}</div>
              </Flex>
              {dateDuration && (
                <span className={styles.weekDuration}>
                  {formatDurationCustom(dateDuration || 0)}
                </span>
              )}
            </Flex>
          );
        },
      },
      event: (props: { event: WorklogEvent }) => {
        const style = {
          paddingLeft: "5px",
          height: "100%",
        };
        const handleHover = (event) => {
          event.stopPropagation();
          // Additional code if needed
        };
        const content = (
          <div>
            <Flex direction={Flex.directions.COLUMN} align={Flex.align.START}>
              <Flex direction={Flex.directions.ROW}>
                {props.event.resource.externalItem?.board?.workspace?.settings
                  .icon.image && (
                  <Avatar
                    size={16}
                    style={{
                      backgroundColor:
                        props.event.resource.externalItem?.board?.workspace
                          ?.settings.icon.color || "#fff",
                      marginRight: "5px",
                    }}
                    src={
                      <img
                        src={
                          props.event.resource.externalItem?.board?.workspace
                            ?.settings.icon.image
                        }
                        alt="avatar"
                      />
                    }
                  />
                )}
                {!props.event.resource.externalItem?.board?.workspace?.settings
                  .icon.image &&
                  props.event.resource.externalItem?.board?.workspace?.settings
                    .icon.color && (
                    <Avatar
                      size={14}
                      style={{
                        backgroundColor:
                          props.event.resource.externalItem?.board?.workspace
                            ?.settings.icon.color || "#fde3cf",
                        marginRight: "5px",
                      }}
                    >
                      {props.event.resource.externalItem?.board?.workspace
                        ?.name &&
                        props.event.resource.externalItem?.board?.workspace
                          ?.name[0]}
                    </Avatar>
                  )}
                <div>
                  {props.event.resource.externalItem?.board?.workspace?.name ??
                    ""}
                </div>
              </Flex>
              <Flex direction={Flex.directions.ROW}>
                <div style={{ marginRight: "5px" }}>
                  <PanelLeft size={14} />
                </div>
                {props.event.resource.externalItem?.board?.name ?? ""}
              </Flex>
              <Flex
                direction={Flex.directions.ROW}
                align={Flex.align.START}
                justify={Flex.justify.START}
              >
                {/* <div
                  className={styles.circle}
                  style={{
                    backgroundColor:
                      props.event.resource.externalItem?.group?.color || "#FFF",
                    marginRight: "5px",
                  }}
                ></div> */}
                <Avatar
                  size={14}
                  style={{
                    backgroundColor:
                      props.event.resource.externalItem?.group?.color || "#FFF",
                    marginRight: "5px",
                  }}
                ></Avatar>
                <div>
                  {props.event.resource.externalItem?.group?.title ?? ""}
                </div>
              </Flex>
              <div>{props.event.resource.externalItem?.name}</div>
              <div
                style={{
                  fontWeight: "bold",
                  alignSelf: "flex-end",
                  justifySelf: "flex-end",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <div style={{ alignSelf: "end" }}>
                  {formatDurationCustom(props.event.resource.duration)}
                </div>
                <div className={styles.timeRangeInfo}>
                  <Flex>
                    <div>{dayjs(props.event.start).format("hh:mm A")}</div>
                    {"–"}
                    <div>{dayjs(props.event.end).format("hh:mm A")}</div>
                  </Flex>
                </div>
              </div>
            </Flex>
          </div>
        );
        return (
          <>
            <div
              className="monday-storybook-tooltip_title"
              style={{ height: "100%" }}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            >
              <Popover content={content}>
                <div
                  className={styles.event}
                  style={{
                    borderLeft: `5px solid ${
                      props.event.resource.externalItem?.group?.color || "#FFF"
                    }`,
                  }}
                >
                  <Flex
                    direction={Flex.directions.COLUMN}
                    align={Flex.align.START}
                    justify={Flex.justify.SPACE_BETWEEN}
                    style={{ height: "100%" }}
                  >
                    <div style={{ padding: "5px" }}>
                      <div>{props.event.resource.externalItem?.name}</div>
                      {props.event.resource.comment && (
                        <div className={styles.commentInfo}>
                          {props.event.resource.comment}
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        paddingTop: "5px",
                        fontWeight: "bold",
                        alignSelf: "flex-end",
                        justifySelf: "flex-end",
                        marginBottom: "5px",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      <div style={{ alignSelf: "end", paddingTop: "5px" }}>
                        {formatDurationCustom(props.event.resource.duration)}
                      </div>
                      <div className={styles.timeRangeInfo}>
                        <Flex>
                          <div>
                            {dayjs(props.event.start).format("hh:mm A")}
                          </div>
                          {"–"}
                          <div>{dayjs(props.event.end).format("hh:mm A")}</div>
                        </Flex>
                      </div>
                    </div>
                  </Flex>
                </div>
              </Popover>
            </div>
          </>
        );
      },
    };
  }, [durationByDate]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vh",
        }}
      >
        <Spin fullscreen={true} tip="Loading..." size="large" />
      </div>
    );
  }

  // RETURN
  return (
    <div className={styles.container}>
      <DragAndDropCalendar
        components={components}
        onNavigate={handleNavigate}
        onView={handleView}
        dayLayoutAlgorithm="no-overlap"
        defaultDate={defaultDate}
        defaultView={Views.WEEK}
        events={events}
        localizer={localizer}
        showMultiDayTimes
        step={30}
        views={allViews}
        selectable
        popup={false}
        onSelectSlot={addEvent}
        onSelectEvent={handleSelectEvent}
        drilldownView="day"
        selected={selected}
        showAllEvents={true}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        tooltipAccessor={null}
        messages={{
          date: "Date",
          time: "Time",
          event: "Event",
          allDay: "All Day",
          week: "Week",
          work_week: "Work Week",
          day: "Day",
          month: "Month",
          previous: "Previous",
          next: "Next",
          yesterday: "Yesterday",
          tomorrow: "Tomorrow",
          today: "Today",
          agenda: "Agenda",
          noEventsInRange: "There are no events in this range.",
          showMore: function showMore(total) {
            return "+" + total + " more";
          },
        }}
      />

      {modalEditIsOpen && (
        <AddTime
          isModalOpen={modalEditIsOpen}
          onCloseModal={closeEditModal}
          worklog={selectedEvent?.resource as Worklog} // TODO: change this dependence
          dispatch={dispatch}
          sessionToken={props.sessionToken}
          userId={props.userId as string}
          theme={props.theme}
        />
      )}

      {modalAddIsOpen && (
        <AddTime
          isModalOpen={modalAddIsOpen}
          onCloseModal={closeModal}
          selectedSlot={selectedSlot as SlotInfo}
          dispatch={dispatch}
          sessionToken={props.sessionToken}
          userId={props.userId as string}
          theme={props.theme}
        />
      )}
    </div>
  );
}
